export const types = {
  setLoadingStatus: "[loadingAction] setLoadingStatus",
  setCalculing: "[loadingAction] setCalculing",
  setLoginErr: "[authErrAcion] setLoginErr",
  storeUserData: "[loginAcion] storeUserData",
  setLogged: "[loginAcion] setLogged",

  setRegisterDeviceErr: "[authErrAcion] setRegisterDeviceErr",
  setBrowserAllowed: "[loginAcion] setBrowserAllowed",

  //// notificaciones
  notification: "[notificacionAction] notification",
  storeVersionBackend: "[notificacionAction] storeVersionBackend",

  ///
  storeClientes: "[clientesActions] storeClientes",
  storeCliente: "[clientesActions] storeCliente",

  setValueCliente: "[clientesActions] setValueCliente",
  cleanClienteForm: "[clientesActions] cleanClienteForm",
  setPaginaCliente: "[clientesActions] setPaginaCliente",
  setShowLogo: "[clientesActions] setShowLogo",

  ////////Proveedores

  storeBancos: "[proveedoresActions] storeBancos",
  setCambiandoEstatus: "[proveedoresActions] setCambiandoEstatus",

  setPaginaDetalleSucursal: "[proveedoresActions] setPaginaDetalleSucursal",

  storeProveedores: "[proveedoresActions] storeProveedores",
  storeProveedor: "[proveedoresActions] storeProveedor",
  changeSucursal: "[proveedoresActions] changeSucursal",
  initPostServiciosProveedor: "[proveedoresActions] initPostServiciosProveedor",
  updatePostServiciosFullProveedor:
    "[proveedoresActions] updatePostServiciosFullProveedor",

  setValueProveedor: "[proovedoresActions] setValueProveedor",
  cleanProveedorForm: "[proovedoresActions] cleanProveedorForm",
  setPagina: "[proovedoresActions] setPagina",
  updateServiciosProveedor: "[proovedoresActions] updateServiciosProveedor",
  storeFilesProveedor: "[proovedorFilesActions] storeFilesProveedor",

  pickTipoAsistenciaProveedor:
    "[proovedoresActions] pickTipoAsistenciaProveedor",
  setSucursalSelected: "[proovedorFilesActions] setSucursalSelected",
  storeEstados: "[proovedorActions] storeEstados",
  storeMunicipios: "[proovedorActions] storeMunicipios",
  setFiltroProveedores: "[proovedorActions] setFiltroProveedores",
  clearFiltroProveedores: "[proovedorActions] clearFiltroProveedores",

  //////////////////////

  storeTiposAsistencia: "[catalogosActions] storeTiposAsistencia",

  setValueTipoAsistencia: "[TipoAsistenciasActions] setValueTipoAsistencia",
  cleanTipoAsistenciaForm: "[TipoAsistenciasActions] cleanTipoAsistenciaForm",
  storeTipoAsistenciaSelected:
    "[TipoAsistenciasActions] storeTipoAsistenciaSelected",
  storeListas: "[TipoAsistenciasActions] storeListas",
  setServicioSelected: "[TipoAsistenciasActions] setServicioSelected",
  changeServicio: "[serviciosActions] changeServicio",
  cleanServicioSelected: "[serviciosActions] cleanServicioSelected",

  storeCampos: "[TipoAsistenciasActions] storeCampos",

  //
  storeCampania: "[campaniasActions] storeCampania",
  setValueCampania: "[campaniasActions] setValueCampania",
  cleanCampaniaForm: "[campaniasActions] cleanCampaniaForm",
  pickTipoAsistencia: "[campaniasActions] pickTipoAsistencia",
  initPostServicios: "[campaniasActions] initPostServicios",
  updateServicios: "[campaniasActions] updateServicios",
  updatePostServiciosFull: "[campaniasActions] updatePostServiciosFull",
  cleanCampaniaName: "[campaniasActions] cleanCampaniaName",
  storeFiles: "[campaniasActions] storeFiles",
  setScript: "[campaniasActions] setScript",

  ///
  setLoadingCampaniaServicios: "[loadingActions] setLoadingCampaniaServicios",
  setLoadingCampos: "[loadingActions] setLoadingCampos",

  // listas
  storeBancos: "[listasActions] storeBancos",
  storeListas: "[listasActions] storeListas",
  setListaSelected: "[listasActions] setListaSelected",
  setListaCampos: "[listasActions] setListaCampos",
  setLoadingCampos: "[listasActions] setLoadingCampos",
  storeListaCampos: "[listasActions] storeListaCampos",

  // map
  setMap: "[mapActions] setMap",
  addPoligono: "[mapActions] addPoligono",

  // AccesoBackOffice
  storeAccesoBackOffice: "[accesoBackOfficeActions] storeAccesoBackOffice",
  cleanAccesoBackOffice: "[accesoBackOfficeActions] cleanAccesoBackOffice",

  // USUARIOS
  storeUsuarios: "[usuariosActions] storeUsuarios",
  changeUsuarioForm: "[usuariosActions] changeUsuarioForm",
  setMensaje: "[usuariosActions] setMensaje",
  setShowForma: "[usuariosActions] setShowForma",
  storeUsusario: "[usuariosActions] storeUsusario",

  // asistencias
  storeServicios: "[asistenciasActions] storeServicios",
  setFiltroAsistencia: "[asistenciasActions] setFiltroAsistencia",
  setAsistenciasLoading: "[asistenciasActions] setAsistenciasLoading",

  // pagos

  setOrdenesPagoTxtIDs: "[pagosActions] setOrdenesPagoTxtIDs",

  setFiltroProveedoresMes: "[pagosActions] setFiltroproveedoresMes",
  storeProveedoresMes: "[pagosActions] storeProveedoresMes",

  setFiltroClientesMes: "[pagosActions] setFiltroClientesMes",
  storeClientesMes: "[pagosActions] storeClientesMes",

  storeOrdenesPagoByEstatus: "[pagosActions] storeOrdenesPagoByEstatus",
  storeOrdenesPagoValidarFactura:
    "[pagosActions] storeOrdenesPagoValidarFactura",
  storeOrdenesPagoAutorizar: "[pagosActions] storeOrdenesPagoAutorizar",
  storeOrdenesPagoPagar: "[pagosActions] storeOrdenesPagoPagar",
  storeOrdenesPagoPorCargarPago: "[pagosActions] storeOrdenesPagoPorCargarPago",

  clearOrdenesPagoByEstatus: "[pagosActions] clearOrdenesPagoByEstatus",

  setFiltroOrdenesPago: "[pagosActions] setFiltroOrdenesPago",
  storeAsistenciasSinPago: "[pagosActions] storeAsistenciasSinPago",
  storeOrdenesPago: "[pagosActions] storeOrdenesPago",
  storeOrdenPago: "[pagosActions] storeOrdenPago",
  storeListaAsistenciasElegibles:
    "[pagosActions] storeListaAsistenciasElegibles",
  setAsistenciasElegibles2: "[pagosActions] setAsistenciasElegibles2",

  setOrdenToEmailLoading: "[pagosActions] setOrdenToEmailLoading",
  setCargandoOrdenesSinPago: "[pagosActions] setCargandoOrdenesSinPago",
  setEnviandoProveedor: "[pagosActions] setEnviandoProveedor",
  storeDashboard1: "[pagosActions] storeDashboard1",
  storeOrdenPagoItems: "[pagosActions] storeOrdenPagoItems",
  cleanOrdenPago: "[pagosActions] cleanOrdenPago",

  //// Asistencias
  storeAsistencia: "[asistenciasActions] storeAsistencia",
  storeSucursal: "[asistenciasActions] storeSucursal",
  cleanFormaSucursal: "[asistenciasActions] cleanFormaSucursal",
  setAsistenciaDato: "[asistenciasActions] setAsistenciaDato",
  storeServicioAsistencia: "[asistenciasActions] storeServicioAsistencia",
  storeProveedoresByText: "[asistencias actions] storeProveedoresByText",
  clearProveedoresByTexto: "[asistencias actions] clearProveedoresByTexto",
  storeSucursalesByProveedor:
    "[asistencias actions] storeSucursalesByProveedor",
  setSucursalSelected: "[asistencias actions] setSucursalSelected",
  setProveedorSelected: "[asistencias actions] setProveedorSelected",
  setCostoCambioEspecial: "[asistencias actions] setCostoCambioEspecial",
  setConclusionCambioEspecial:
    "[asistencias actions] setConclusionCambioEspecial",
  setEstatusFinalSelected: "[asistencias actions] setEstatusFinalSelected",

  ////////  ASIGNACION
  storeAsistenciasAsignables: "[asignacion actions] storeAsistenciasAsignables",
  setLoadingProveedores: "[asignacion actions] setLoadingProveedores",
  setLoadingAsistencias: "[asignacion actions] setLoadingAsistencias",
  storeProveedoresServicio: "[asignacion actions] storeProveedoresServicio",
  resultadoAsignacion: "[asignacion actions] resultadoAsignacion",

  /// Reportes
  storeAsistenciasMes: "[reportes actions] storeAsistenciasMes",
  setFiltroReporte: "[reportes actions] setFiltroReporte",
  setLoadingReporte: "[reportes actions] setLoadingReporte",
  setOrdenPagarFiltered: "[reportes actions] setOrdenPagarToView",
  changeValidationVersion: "[appReducer] changeValidationVersion",
  showModalValidVersion: "[appReducer] changeShoModal",
  storeAsistenciasDia: "[reportes actions] storeAsistenciasDia",
  storePagosProveedorByFecha: "[reportes actions] storePagosProveedorByFecha",
};
