import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { consLogged } from "../constants/consLogged";
import Login from "../views/auth/Login";
import { startGetBancos } from "../context/actions/proveedoresActions";
import { useDispatch } from "react-redux";
import { ValidateVersion } from "../shared/ValidateVersion";

////////////////////////////////////////////////////
export default function PrivateRoute({
                                       isAuthenticated,
                                       component: Component,
                                       ...rest
                                     }) {




  ////////////////////////////////////////////////////
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated === consLogged.LOGGED ? (
          <>
            <ValidateVersion/>
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login"/>
        )
      }
    />
  );
}
